/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch } from 'react-redux';
import { useState, useEffect, useRef, useCallback } from 'react';
import { PropertyFilterProps } from '@amzn/awsui-components-react';

import { logger } from 'modules/core';
import { TableRow } from 'modules/core/model';
import { purchaseListService } from '../services';
import { showErrorPage } from 'modules/app/store';
import { PurchaseIndex } from '@amzn/merp-core/models/purchase';
import { mapToPurchaseIndexTableRow } from 'modules/core/mappers/purchase.mappers';

export function usePaginatedPurchasesV2(filter: 'purchaseOrder' | 'purchaseRequest') {
    const [loading, setLoading] = useState(true);
    const [items, setTableItems] = useState<TableRow<PurchaseIndex>[]>([]);
    const [confirmFailedItems, updateConfirmFailedItems] = useState<string[]>([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const from = useRef<number>(0);

    const [filterText, setFilterText] = useState<string>('');

    const defaultPageSize = 60;

    const dispatch = useDispatch();

    const selectedItems = useCallback(() => items?.filter(item => item.selected) || [], [items]);

    const confirmReceiptsAsync = async () => {
        if (!items) return;

        const selected = items.filter(i => i.selected);

        if (!selected) {
            return;
        }

        const purchaseIds = selected.map(s => s.payload.purchaseId);
        await purchaseListService.confirmReceiptsAsync(purchaseIds);

        resetPurchaseList();

        await paginateFromServerAsyncV2();
    };

    const paginateFromServerAsyncV2 = useCallback(
        async (filteringQuery?: PropertyFilterProps.Query) => {
            setLoading(true);
            const purchaseIndexItems: PurchaseIndex[] = await fetchRecords(filteringQuery);
            setHasNextPage(purchaseIndexItems.length !== 0 && purchaseIndexItems.length >= defaultPageSize);

            const newItems = purchaseIndexItems.map(mapToPurchaseIndexTableRow);
            onPaginatedResultUpdated(newItems || []);

            setLoading(false);
        },
        [filter, dispatch]
    );

    const onPaginatedResultUpdated = (updatedItems: TableRow<PurchaseIndex>[]) => {
        setTableItems(current => {
            const newItems = current || [];
            if (updatedItems) {
                updatedItems.map(row => (row.number = newItems.length + row.number));
                newItems.push(...updatedItems);
            }

            return newItems;
        });
    };

    const fetchRecords = async (filteringQuery?: PropertyFilterProps.Query) => {
        let result;
        try {
            const query = setUpQueryForV2(filteringQuery);

            if (filter === 'purchaseOrder') {
                result = await purchaseListService.getPurchaseOrdersAsyncV2(query);
            } else {
                result = await purchaseListService.getPurchaseRequestsAsyncV2(query);
            }

            if (result.items === undefined) {
                dispatch(showErrorPage({ errorCode: result.status || 400, accessError: true }));
            } else {
                const newFrom = from.current + result.items.length;
                from.current = newFrom;
            }
        } catch (e) {
            logger.error(e);
        }

        return result?.items || [];
    };

    const setUpQueryForV2 = (filteringQuery?: PropertyFilterProps.Query) => {
        const queryForV2 = {};
        Object.assign(queryForV2, { pageSize: defaultPageSize });
        Object.assign(queryForV2, { from: from.current });

        if (!filteringQuery) {
            return queryForV2;
        }

        const searchBy = getFreeTextSearchBy(filteringQuery?.tokens);

        if (searchBy !== '') {
            Object.assign(queryForV2, { searchBy: searchBy });
        } else {
            if (filteringQuery?.tokens) {
                filteringQuery.tokens.forEach(token => {
                    const propertyKey = token.propertyKey;
                    Object.assign(queryForV2, { [propertyKey as string]: token.value });
                });
            }
        }

        Object.assign(queryForV2, { combination: filteringQuery?.operation.toUpperCase() });

        return queryForV2;
    };

    const resetPurchaseList = () => {
        from.current = 0;
        setTableItems([]);
    };

    const getFreeTextSearchBy = (tokens?: PropertyFilterProps.Query['tokens']) => {
        let result = '';
        if (tokens === undefined) {
            return result;
        } else {
            tokens.forEach(token => {
                if (token.propertyKey === undefined) {
                    result = token.value;
                }
            });
        }
        return result;
    };

    useEffect(() => {
        paginateFromServerAsyncV2();
    }, [paginateFromServerAsyncV2]);

    return {
        items,
        loading,
        hasNextPage,
        filterText,
        confirmFailedItems,
        updateConfirmFailedItems,
        selectedItems,
        setTableItems,
        confirmReceiptsAsync,
        paginateFromServerAsyncV2,
        setFilterText,
        resetPurchaseList
    };
}
